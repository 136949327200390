import findIndex from 'lodash/findIndex';
import { getBase64 } from '@/utils/util';

const SIZE = 10 * 1024 * 1024;

export default {
  data() {
    return {
      isFileValid: false,
    };
  },
  methods: {
    checkFileSize(file) {
      const isSizeFit = file.size < SIZE;
      if (!isSizeFit) {
        this.$message.error('Image must smaller than 10MB!');
      }
      return isSizeFit;
    },
    handleBeforeUpload(file) {
      const isValidSize = this.checkFileSize(file);
      this.isFileValid = false;
      if (isValidSize) {
        this.form.images = [...this.form.images, file];
        this.isFileValid = true;
      }
      return false;
    },
    async handleImageChange({ file }) {
      if (!this.isFileValid) {
        return false;
      }

      getBase64(file, (data) => {
        this.fileList.push({ url: data, uid: file.uid });
      });

      return false;
    },
    removeFromImages(file) {
      const id = file.id || file.uid;
      const index = findIndex(this.form.images, (img) => img.id === id || img.uid === id);
      const newImages = this.form.images.slice();

      if (index !== -1) {
        newImages.splice(index, 1);
        this.form.images = newImages;
      }
    },
  },
};
